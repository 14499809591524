import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as GatsbyLink, graphql, Script } from 'gatsby';

import Link from '/src/components/LocalizedLink';
import Layout from '../components/Layout';
import ForthcomingCourses from '../components/ForthcomingCourses';
import ReviewsSection from '../components/ReviewsSection';
// import SubscriptionForm from '../components/SubscriptionForm';

import { URL_KEYS_ROUTES_EN } from '../constants/routes';

import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';
import IconArrowRightRed from '../assets/images/svg/arrow-right-red.inline.svg';
import img1 from '../assets/images/home-page/partners/Logo no background.png';
import img2 from '../assets/images/home-page/partners/Trinity logo no background.png';
import img3 from '../assets/images/home-page/partners/bildungsurlaub_mVonZgX.png';
import img4 from '../assets/images/home-page/partners/Monochrome on Transparent.png';
// import img5 from '../assets/images/home-page/partners/Languages4Life logo.png';
import imgBenefit1 from '../assets/images/home-page/help-s/Internationally Recognized Qualification.svg';
import imgBenefit2 from '../assets/images/home-page/help-s/Classroom Confidence Building.svg'; //
import imgBenefit3 from '../assets/images/home-page/help-s/Post-Grad Job Prospects.svg';
import imgBenefit4 from '../assets/images/home-page/help-s/Varied Learning Styles.svg';
import imgBenefit5 from '../assets/images/home-page/help-s/Small Group Sizes .svg';
import imgDots from '../assets/images/home-page/Dots.png';
import imgDots2 from '../assets/images/home-page/Dots 2.svg';
import imgPhoto_r from '../assets/images/home-page/Photo_r.png';
import imgPhoto_l from '../assets/images/home-page/Photo_l.png';
import imgAccomodation from '../assets/images/Accommodation.svg';
import imgStudentVisas from '../assets/images/Student visas.svg';
import imgCareersAdvice from '../assets/images/Careers advice.svg';
// import fileTEFLIberiaBrochure from '../assets/files/TEFL Iberia brochure web.pdf';

import '../assets/styles/pages/home-page.scss';

const IndexPage = ({ data }) => {
  const allPrismicArticle = data?.allPrismicArticle?.edges;
  const prismicHomePage = data?.prismicHomePage?.data;

  return (
    <Layout
      title={'Spanish Courses in Barcelona with Iberia Language Academy '}
      description={
        'Learn Spanish in Barcelona with Iberia Language Academy. Small group classes, cultural immersion, student visas. Start your Spanish journey today!'
      }
    >
      <div className="home-page">
        <section className="c-first-section c-first-section--lg">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-9 col-xl-7">
                <h1 className="c-first-section__title">
                  Learn Spanish and Unlock a World of Possibilities
                </h1>
                <p className="c-first-section__description">
                  Enrol with Iberia Language Academy and start learning Spanish in Barcelona
                </p>
                <div className="c-first-section__btns">
                  <FormattedMessage id={URL_KEYS_ROUTES_EN.APPLY}>
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--red">
                        Sign up now
                        <IconArrowRight />
                      </Link>
                    )}
                  </FormattedMessage>
                  {/* <Link to={'/apply'} className="c-btn c-btn--red">
                    Sign up now
                    <IconArrowRight />
                  </Link> */}

                  {/* <Link to="/covid" className="c-btn c-btn--custom">
                    Coronavirus FAQs
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="second-section">
          <div className="container">
            <div className="second-section__wrapper">
              <div className="c-title-13  partner-title">ILA is affiliated with</div>
              <div className="partners">
                <div className="partner">
                  <img src={img1} alt="TEFL Iberia" />
                </div>
                <div className="partner">
                  <img src={img2} alt="Trinity College London" />
                </div>
                <div className="partner">
                  <img src={img3} alt="Bildungsurlaub" />
                </div>
                <div className="partner">
                  <img src={img4} alt="Eureka" />
                </div>
              </div>
            </div>

            {/* CARDS */}
            <ForthcomingCourses
              specialHomepageOutlook
              specialHomepageCardsData={prismicHomePage?.forthcoming_courses?.slice(0, 3)}
              title={'Upcoming Spanish Courses'}
            />
            {/* CARDS */}
          </div>
        </section>
        <div className="c-section-py">
          <Script
            src="https://static.elfsight.com/platform/platform.js"
            data-use-service-core
            defer
          />

          <div
            className="elfsight-app-9e51884d-880b-4c3f-bc24-bf239af0bc6a"
            data-elfsight-app-lazy
          />
        </div>{' '}
        {/*
        <section className="cta-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col">
                <SubscriptionForm />
              </div>
            </div>
          </div>
        </section> */}
        <section className="nineth-section c-section-py">
          <div className="container">
            <h2 className="c-title-38">
              Test Your <span className="colored no-link">Spanish!</span>{' '}
            </h2>
            <p className="c-text-18">Check your level with our free level test.</p>

            <a
              target="_blank"
              href="https://form.jotform.com/231496158857369"
              className="c-btn c-btn--red"
              rel="noopener noreferrer"
            >
              Test Your Spanish
              <IconArrowRight />
            </a>
          </div>
        </section>
        <section className="third-section">
          <div className="third-section__inner">
            <div className="wrapper container">
              <div className="block_l">
                <h3 className="c-title-34">ILA Helps Students Thrive. Here’s How:</h3>
              </div>
              <div className="block_r">
                <img src={imgDots} alt="" className="block_r__dots" />
                <div className="benefit">
                  <img src={imgBenefit1} alt="Small group learning" />
                  <div className="benefit__text">
                    <h4 className="c-title-18">Small group learning</h4>
                    <p className="c-text-14">
                      We strictly limit the size of each group to 8 in order to maximise your time
                      with the teacher.
                    </p>
                  </div>
                </div>

                <div className="benefit">
                  <img src={imgBenefit2} alt="Local culture" />
                  <div className="benefit__text">
                    <h4 className="c-title-18">Focus on speaking</h4>
                    <p className="c-text-14">
                      Lots of speaking practice with our native Spanish teachers means you’ll
                      achieve fluency in no time!
                    </p>
                  </div>
                </div>

                <div className="benefit">
                  <img src={imgBenefit3} alt="Student Visas" />
                  <div className="benefit__text">
                    <h4 className="c-title-18">Cultural activities</h4>
                    <p className="c-text-14">
                      Enjoys trips to markets, museums, neighbourhood cafés and more!
                    </p>
                  </div>
                </div>

                <div className="benefit">
                  <img src={imgBenefit4} alt="Bilingual Support" />
                  <div className="benefit__text">
                    <h4 className="c-title-18">Student Visas</h4>
                    <p className="c-text-14">
                      Live and work in Barcelona with our long-term student visa courses.
                    </p>
                  </div>
                </div>

                <div className="benefit">
                  <img src={imgBenefit5} alt="Make Connections" />
                  <div className="benefit__text">
                    <h4 className="c-title-18">Best prices</h4>
                    <p className="c-text-14">
                      Excellent service and world-class facilities at the most accessible prices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="fourth-section c-section-py">
          <ReviewsSection title={{ id: 'graduates.title' }} />
        </section>
        <section className="fifth-section">
          <div className="wrapper c-section-py">
            <div className="container block_l">
              <div className="row no-gutters">
                <div className="col-xl-12 offset-xl-2">
                  <div className="content">
                    <h2 className="c-title-34">
                      Are you ready to be part of our growing community of{' '}
                      <span>Spanish speakers?</span>
                    </h2>
                    <p className="c-text-18">
                      Students come from all over the world to study with ILA, eager to experience
                      the adventure of living in Spain, immerse themselves in a new culture, and
                      gain confidence in speaking Spanish.
                    </p>
                    <div className="block_l__bottom">
                      <div className="item">
                        <h2 className="c-title-42">8</h2>
                        <p className="c-text-14">Maximum number of students per group</p>
                      </div>
                      <div className="item">
                        <h2 className="c-title-42">98%</h2>
                        <p className="c-text-14">Student visa approvals</p>
                      </div>
                      <div className="item">
                        <h2 className="c-title-42">20+</h2>
                        <p className="c-text-14">Nationalities represented by our alumni</p>
                      </div>
                    </div>
                  </div>
                  <div className="block_r">
                    <div className="block_r__img">
                      <img src={imgPhoto_r} alt="imgPhoto_r" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sixth-section c-section-py">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h2 className="c-title-38 text-center mx-auto">Got Questions? Ask Us Anything</h2>
                </div>
                <div className="c-btn-wrapper">
                  <FormattedMessage id="url.contact.index">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--red">
                        Contact Us
                        <IconArrowRight />
                      </Link>
                    )}
                  </FormattedMessage>
                  <FormattedMessage id="url.tefl-iberia-brochure.index">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--red-border">
                        Download Brochure
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="seventh-section c-section-py">
          <div className="container">
            <h2 className="c-title-34">You’re Always In Good Hands!</h2>
            <p className="c-text-18">
              ILA is more than just a Spanish school. Our amazing student support team helps with
              accommodation, visas, bureaucracy and more.
            </p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-1">
                <div className="img_l">
                  <img src={imgPhoto_l} alt="imgPhoto_l" />
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-12 offseted">
                <div className="item">
                  <img src={imgStudentVisas} alt="Student Visas" />
                  <h3 className="c-title-22">Student Visas</h3>
                  <p className="c-text-14">
                    Do you want to live and work long-term in Barcelona? Our expert team can help
                    you navigate the Spanish visa system.
                  </p>
                  <div className="item__btns">
                    <FormattedMessage id={URL_KEYS_ROUTES_EN.STUDENT_VISAS}>
                      {(locale) => (
                        <Link to={locale[0]} className="c-btn c-btn--white">
                          Find out more
                          <IconArrowRightRed />
                        </Link>
                      )}
                    </FormattedMessage>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-5 col-md-6">
                <div className="item">
                  <img src={imgAccomodation} alt="Accommodation" />
                  <h3 className="c-title-22">Accommodation</h3>
                  <p className="c-text-14">
                    Let us take the stress out of finding accommodation in Barcelona, so you can
                    focus on your Spanish studies. Comfortable, affordable and close to the school.
                  </p>
                  <div className="item__btns">
                    <FormattedMessage id={URL_KEYS_ROUTES_EN.ACCOMODATION}>
                      {(locale) => (
                        <Link to={locale[0]} className="c-btn c-btn--white">
                          Find out more
                          <IconArrowRightRed />
                        </Link>
                      )}
                    </FormattedMessage>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="item">
                  <img src={imgCareersAdvice} alt="Cultural Activities" />
                  <h3 className="c-title-22">Cultural Activities</h3>
                  <p className="c-text-14">
                    Take part in walking tours, day trips, gallery visits and more with our fun and
                    affordable cultural activities programme.
                  </p>
                  <div className="item__btns">
                    <FormattedMessage id={URL_KEYS_ROUTES_EN.CULTURAL_ACTIVITIES}>
                      {(locale) => (
                        <Link to={locale[0]} className="c-btn c-btn--white">
                          Find out more
                          <IconArrowRightRed />
                        </Link>
                      )}
                    </FormattedMessage>
                  </div>
                </div>
                <img className="dots" src={imgDots2} alt="imgDots2" />
              </div>
            </div>
          </div>
        </section>
        <section className="eight-section">
          <div className="container">
            <div className="top">
              <h2 className="c-title-34">Latest From Our Blog</h2>
              <FormattedMessage id="url.blog">
                {(locale) => (
                  <Link to={locale[0]} className="c-btn c-btn--white">
                    <FormattedMessage id="homepage.sec8.seeAllPosts" />
                    <IconArrowRightRed />
                  </Link>
                )}
              </FormattedMessage>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                {allPrismicArticle?.slice(0, 1)?.map(({ node }) => (
                  <GatsbyLink to={`/blog/${node.uid}`} className="post post--md" key={node.uid}>
                    <div className="post--md__img">
                      {node?.data?.image && (
                        <img src={node.data.image.url} alt={node.data?.title?.text} />
                      )}
                    </div>
                    <div className="post--md__content">
                      <h3 className="c-title-20">{node.data?.title?.text}</h3>
                      <p className="c-text-14">{node.data?.content?.text.slice(0, 275)}...</p>
                      <div className="c-btn c-btn--white">
                        Read more
                        <IconArrowRightRed />
                      </div>
                    </div>
                  </GatsbyLink>
                ))}
              </div>
              <div className="col-lg-7 d-flex">
                {allPrismicArticle?.slice(1)?.map(({ node }) => (
                  <GatsbyLink to={`/blog/${node.uid}`} className="post post--sm" key={node.uid}>
                    <div className="post--sm post--sm__img">
                      {node?.data?.image && (
                        <img src={node.data.image.url} alt={node.data?.title?.text} />
                      )}
                    </div>
                    <div className="post--sm__content">
                      <h3 className="c-title-20">{node.data?.title?.text}</h3>
                      <p className="c-text-14">{node.data?.content?.text.slice(0, 125)}...</p>
                    </div>
                  </GatsbyLink>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="nineth-section c-section-py">
          <div className="container">
            <h2 className="c-title-38">
              Ready to Start Learning Spanish in{' '}
              <span className="colored no-link"> Spain’s most exciting city?</span>
            </h2>
            <p className="c-text-18">
              Our experienced and passionate team at Iberia Language Academy are ready to help you
              every step of the way to Barcelona.
            </p>

            <FormattedMessage id="url.apply.index">
              {(locale) => (
                <Link to={locale[0]} className="c-btn c-btn--red">
                  <FormattedMessage id="menu.applyNow" />
                  <IconArrowRight />
                </Link>
              )}
            </FormattedMessage>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicArticle(limit: 4, sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            content {
              text
            }
            image {
              url
            }
          }
        }
      }
    }
    prismicHomePage {
      data {
        forthcoming_courses {
          title {
            text
          }
          timing {
            text
          }
          image {
            url
          }
          link
          description
        }
      }
    }
  }
`;

export default IndexPage;
